import React from 'react'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import YouTube from 'components/youtube'
import { Helmet } from 'react-helmet'
import Button from 'components/button/button'
import { graphql, Link } from 'gatsby'
import Layout from 'components/layout'
import Content from 'components/new/content'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import H1 from 'components/new/typography/h1'
import H2 from 'components/new/typography/h2'
import H3 from 'components/new/typography/h3'
import H4 from 'components/new/typography/h4'
import P from 'components/new/typography/p'
import Ul from 'components/new/ul'
import Spacer from 'components/new/spacer'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import CalloutSection from 'components/new/callout-section'
import Disclaimer from 'components/landing-page/disclaimer'
import AdditionalResources from 'components/new/additional-resources'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import convertArrayToInlineList from 'utils/convert-array-to-inline-list'

const AgTechProgramPage = props => {
  const {
    data: {
      allSanityLocation: { states: allSanityLocationStates, totalCount: locationCount },
      gageHeadshot,
      heroImage,
      heroImageMobile,
      nextStepImage,
      asubImage,
      lakeLandImage,
      nwMississippiImage,
      owensImage,
      vincennesImage,
    },
  } = props

  const heroImages = withArtDirection(getImage(heroImage), [
    {
      media: '(max-width: 600px)',
      image: getImage(heroImageMobile),
    },
  ])

  allSanityLocationStates.sort((a, b) => b.totalCount - a.totalCount)

  const statesList = allSanityLocationStates.map(state => state.fieldValue)

  const statesListString = convertArrayToInlineList(statesList, { conjunction: 'or' })

  return (
    <Layout>
      <Helmet>
        <title>John Deere Tech Program | Hutson Inc</title>
        <meta
          name='description'
          content="Learn how to troubleshoot, repair, and rebuild the equipment that Hutson customers rely on with the John Deere Tech Program. It's a two-year associates degree where you'll focus on ag and turf equipment at top-tier schools."
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Careers',
                'item': 'https://www.hutsoninc.com/careers/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Tech Program',
                'item': 'https://www.hutsoninc.com/careers/tech-program/',
              },
            ],
          })}
        </script>
      </Helmet>

      <Hero image={heroImages} title='John Deere Tech Program' overlayOpacity={0} />

      <Content kind='full'>
        <H2>What is the John Deere Tech Program?</H2>
        <P>
          The John Deere Tech Program is a two-year associates degree in which you’ll learn how to
          troubleshoot, service, repair and rebuild the equipment that keeps Hutson customers
          running strong. You’ll focus on agriculture and turf equipment where you will work with
          top notch schools to gain the skill and experience necessary for you to become an
          essential member of the Hutson team. As a John Deere Tech student you will receive
          hands-on technical training in school and as part of your internship. You will learn from
          seasoned technicians how to put your skills to work in the shop and in the field. The
          program consists of general education courses and John Deere exclusive classroom training
          courses including:
        </P>
        <CoursesList>
          <li>Diesel Engines and Fuel Systems</li>
          <li>Powertrain Components</li>
          <li>Electrical and Hydraulic Systems</li>
          <li>Service Advisor&trade; Diagnostics</li>
          <li>Heating and Air Conditioning</li>
          <li>Service Department Procedures and Policy</li>
          <li>John Deere Product Specific Training</li>
          <li>Precision Technology</li>
        </CoursesList>
        <Spacer size='m' />
        <Button
          as={ButtonLink}
          to='/careers/service/jobs/?Job%20Type=Internship'
          color='green'
          ghost
        >
          Browse Tech Program positions
        </Button>
      </Content>

      <VideoSection>
        <VideoSectionColumn>
          <VideoContainer>
            <YouTube videoId='g3-6k_AhEOc' />
          </VideoContainer>
        </VideoSectionColumn>
        <VideoSectionColumn>
          <Content kind='full'>
            <VideoSectionTitle>Turn what you love into a career</VideoSectionTitle>
            <VideoSectionP>
              Learn more about the John Deere Tech Program from students who are going through it!
            </VideoSectionP>
          </Content>
        </VideoSectionColumn>
      </VideoSection>

      <Content kind='full'>
        <H2>How to apply</H2>
        <Ul as='ol'>
          <li>
            <strong>Submit an application</strong>.{' '}
            <Link
              to='/careers/service/jobs/?Job%20Type=Internship'
              style={{ color: '#377539' }}
            >
              Apply online
            </Link>{' '}
            or submit a résumé to{' '}
            <A href='mailto:recruiting@hutsoninc.com'>recruiting@hutsoninc.com</A>.
          </li>
          <li>
            <strong>Get an internship</strong>. If open positions are available, we will contact you
            to set up an internship interview. The internship is paid and must be completed the
            summer before beginning classes. Your internship must be completed, evaluated and
            approved before we grant you a sponsorship to the John Deere Tech program.
          </li>
          <li>
            <strong>Apply for school</strong>. You must meet the admission requirements listed below
            and be accepted into one of our partnering John Deere Tech university or college
            programs to begin classes in the program. You will continue to work at your sponsoring
            Hutson location during breaks from school.
          </li>
        </Ul>
        <Spacer size='m' />
        <H3>Admission requirements</H3>
        <Ul>
          <li>Hold a high school diploma or equivalent</li>
          <li>Secure a sponsorship through Hutson</li>
          <li>Meet college admission and academic requirements</li>
        </Ul>
        <Spacer size='m' />
        <Button
          as={ButtonLink}
          to='/careers/service/jobs/?Job%20Type=Internship'
          color='green'
          ghost
        >
          Apply now
        </Button>
      </Content>

      <LightSection>
        <Content kind='full'>
          <H2>Our partnering colleges and universities</H2>
          <Spacer size='m' />
          <Grid>
            <Column>
              <GatsbyImage image={getImage(asubImage)} alt='' />
              <Spacer size='m' />
              <H4 as='h3'>Arkansas State University</H4>
              <P>Beebe, AR</P>
              <ColumnSpacer size='m' />
              <ButtonContainer>
                <Button
                  as={OutboundLink}
                  href='http://www.asub.edu/ag-tech/'
                  ghost
                  color='green'
                  aria-label='Learn more about the Tech Program at Arkansas State University'
                >
                  Learn more
                </Button>
              </ButtonContainer>
            </Column>
            <Column>
              <GatsbyImage image={getImage(lakeLandImage)} alt='' />
              <Spacer size='m' />
              <H4 as='h3'>Lake Land College</H4>
              <P>Mattoon, IL</P>
              <ColumnSpacer size='m' />
              <ButtonContainer>
                <Button
                  as={OutboundLink}
                  href='https://www.jdtechprogram.com/'
                  ghost
                  color='green'
                  aria-label='Learn more about the Tech Program at Lake Land College'
                >
                  Learn more
                </Button>
              </ButtonContainer>
            </Column>
            <Column>
              <GatsbyImage image={getImage(nwMississippiImage)} alt='' />
              <Spacer size='m' />
              <H4 as='h3'>Northwest Mississippi Community College</H4>
              <P>Senatobia, MS</P>
              <ColumnSpacer size='m' />
              <ButtonContainer>
                <Button
                  as={OutboundLink}
                  href='https://www.northwestms.edu/programs/career-technical/technical-programs/ag-tech-j-deere'
                  ghost
                  color='green'
                  aria-label='Learn more about the Tech Program at Northwest Mississippi Community College'
                >
                  Learn more
                </Button>
              </ButtonContainer>
            </Column>
            <Column>
              <GatsbyImage image={getImage(vincennesImage)} alt='' />
              <Spacer size='m' />
              <H4 as='h3'>Vincennes University</H4>
              <P>Vincennes, IN</P>
              <ColumnSpacer size='m' />
              <ButtonContainer>
                <Button
                  as={OutboundLink}
                  href='https://www.vinu.edu/web/guest/john-deere-partnership'
                  ghost
                  color='green'
                  aria-label='Learn more about the Tech Program at Vincennes University'
                >
                  Learn more
                </Button>
              </ButtonContainer>
            </Column>
            <Column>
              <GatsbyImage image={getImage(owensImage)} alt='' />
              <Spacer size='m' />
              <H4 as='h3'>Owens Community College</H4>
              <P>Toledo, OH</P>
              <ColumnSpacer size='m' />
              <ButtonContainer>
                <Button
                  as={OutboundLink}
                  href='https://www.owens.edu/johndeere/'
                  ghost
                  color='green'
                  aria-label='Learn more about the Tech Program at Owens Community College'
                >
                  Learn more
                </Button>
              </ButtonContainer>
            </Column>
          </Grid>
        </Content>
      </LightSection>
      <Content kind='full'>
        <H2>The reward</H2>
        <P>
          Upon graduation, you'll go on to work at one of our{' '}
          <Link to='/locations/'>
            {locationCount} locations in {statesListString}
          </Link>
          . You can expect excellent starting pay, extensive benefits and great working conditions.
          Hutson values your talent, passion and dedication towards completing the John Deere Ag
          Tech Program. Reimbursement for tuition expense will begin following your full-time
          employment and will continue over the next 24 months of employment; only tuition not
          covered by scholarships or grants will be reimbursed.
        </P>
        <BenefitsList>
          <li>
            <strong>Tuition reimbursement</strong>: We will reimburse up to 100%<sup>&dagger;</sup>{' '}
            of your tuition expenses not covered by scholarships or grants.
          </li>
          <li>
            <strong>Regional position at Hutson</strong>: Start full-time after graduation pending
            performance and location capacity.
          </li>
          <li>
            <strong>Competitive pay + benefits.</strong>: We offer competitive pay and benefits
            including health, dental, vision and life insurance, 401(k) matching, and HSA/FSA
            accounts.
          </li>
          <li>
            <strong>Pay for performance + annual bonuses.</strong>: Get rewarded for your hard work,
            earn paid time off, and boost your paycheck with holiday pay opportunities.
          </li>
          <li>
            <strong>Tool support programs + $1,250/year allowance</strong>: Get $1,250 per year to
            add to your kit— plus, they're yours to keep! Don't have any tools? We'll help pay for
            or finance a starter set.
          </li>
          <li>
            <strong>Opportunities for growth</strong>: Advance in your career as you gain experience
            with our service career path.
          </li>
          <li>
            <strong>Continuous training</strong>: Stay up to date on the latest technology with
            training and certification programs.
          </li>
          <li>
            <strong>Employee referral bonus up to $4,000</strong>: Get rewarded if you refer someone
            that makes a great addition to our team.
          </li>
        </BenefitsList>
        <Spacer size='m' />
        <Button as={ButtonLink} to='/careers/' color='green' ghost>
          Learn more about careers at Hutson
        </Button>
        <Spacer size='xxl' />
        <ContactGrid>
          <div>
            <H2>Questions?</H2>
            <P>
              Want to learn more about the program? Schedule a call with Gage Moore, our HR
              Coordinator.
            </P>
            <Spacer size='s' />
            <Button
              as={OutboundLink}
              href='https://calendly.com/gmoore-7/jd-ag-tech-presentation'
              color='green'
              className='cta-button'
              ghost
            >
              Book a call
            </Button>
          </div>
          <ContactCard>
            <ContactCardHeadshot image={getImage(gageHeadshot)} alt='Gage Moore' />
            <div>
              <H4 as='span' className='contact-name'>
                Gage Moore
              </H4>
              <p>
                Human Resources Coordinator
                <br />
                <a href='mailto:gmoore@hutsoninc.com'>gmoore@hutsoninc.com</a>
              </p>
            </div>
          </ContactCard>
        </ContactGrid>
        <Spacer size='xl' />
        <Disclaimer>
          (&dagger;) Hutson will reimburse for tuition expenses not covered by scholarships or
          grants. Reimbursement will occur over a 24-month period starting within 30 days after
          becoming full-time. If the student/employee leaves the company for any reason during the
          24-month period, this agreement will be terminated. Reimbursement varies based on GPA.
        </Disclaimer>
      </Content>
      <NextStepCallout image={getImage(nextStepImage)} overlayOpacity={0.4}>
        <H1 as={CalloutHeading}>Take the next step</H1>
        <CalloutP>
          Interested in working for Hutson? View our current career opportunities and apply online!
        </CalloutP>
        <Spacer size='m' variable />
        <ButtonContainer>
          <Button
            as={ButtonLink}
            to='/careers/service/jobs/?Job%20Type=Internship'
            color='yellow'
          >
            Browse Tech Program positions
          </Button>
        </ButtonContainer>
      </NextStepCallout>
      <AdditionalResources
        resources={[
          {
            title: 'Careers',
            links: [
              <Link to='/careers/'>Careers at Hutson</Link>,
              <Link to='/careers/jobs/'>Browse open positions</Link>,
            ],
          },
          {
            title: 'About Us',
            links: [
              <Link to='/about/'>About us</Link>,
              <Link to='/locations/'>Our locations</Link>,
              <Link to='/contact/'>Contact us</Link>,
            ],
          },
          {
            title: 'Disclosures',
            links: [
              <OutboundLink href='https://www.anthem.com/machine-readable-file/search/'>
                Transparency in coverage
              </OutboundLink>,
            ],
          },
        ]}
      />
    </Layout>
  )
}

const A = styled.a`
  color: #377539;
`

const LightSection = styled.div`
  background-color: ${props => props.theme.color.n20};
`

const Hero = styled(HeroImageTitle)`
  height: 300px;

  @media (min-width: 900px) {
    height: 450px;
  }
`

const ButtonContainer = styled.div`
  a {
    display: inline-block;
    text-decoration: none;
  }
`

const ColumnSpacer = styled(Spacer)``

const Grid = styled.div`
  @media (min-width: 600px) {
    ${clearfix};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`

const Column = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 8px 24px 24px;
  text-align: center;

  h3 {
    text-align: center;
  }

  ${ColumnSpacer} {
    flex-grow: 2;
  }

  @media (max-width: 599px) {
    :not(:last-child) {
      margin-bottom: 16px;
    }
  }

  @media (min-width: 600px) {
    ${column('1/2', 16)};
    margin-bottom: 16px;
  }

  @media (min-width: 900px) {
    ${column('1/3', 16)};
  }

  @media (min-width: 1200px) {
    ${column('1/5', 16)};
    margin-bottom: 0;
  }
`

const CoursesList = styled(Ul)`
  @media (min-width: 480px) {
    columns: 2;
    column-gap: ${props => props.theme.size.m};
  }

  @media (min-width: 900px) {
    columns: 3;
  }
`

const BenefitsList = styled(Ul)`
  @media (min-width: 900px) {
    columns: 2;
    column-gap: ${props => props.theme.size.m};
  }
`

const VideoSection = styled.div`
  background-color: ${props => props.theme.color.n700};

  @media (min-width: 900px) {
    display: flex;
  }
`

const VideoSectionColumn = styled.div`
  @media (min-width: 900px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
  }
`

const VideoContainer = styled.div`
  background-color: ${props => props.theme.color.n900};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`

const VideoSectionTitle = styled(H2)`
  color: #fff;
`

const VideoSectionP = styled(P)`
  color: #fff;
`

const NextStepCallout = styled(CalloutSection)`
  height: 300px;

  @media (min-width: 900px) {
    height: 400px;
  }
`

const CalloutHeading = styled.span`
  color: #fff;

  @media (max-width: 479px) {
    font-size: 36px;
  }
`

const CalloutP = styled(P)`
  color: #fff;
  margin-top: 0;
`

const ContactGrid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .cta-button {
    display: inline-block;
    text-decoration: none;
  }

  @media (min-width: 600px) {
    flex-direction: row;
  }
`

const ContactCardHeadshot = styled(GatsbyImage)`
  max-width: 120px;
  width: 100%;
`

const ContactCard = styled.div`
  background-color: ${props => props.theme.color.n10};
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 0;
  margin-top: 24px;
  padding: 24px;
  text-align: center;

  .contact-name {
    font-weight: 600;
  }

  p a {
    color: ${props => props.theme.color.g400};
    text-decoration: underline;
  }

  p {
    margin: 8px 0 0;
  }

  ${ContactCardHeadshot} {
    margin: 0 auto 16px;
  }

  @media (min-width: 600px) {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-left: 24px;
    margin-top: 0;
    text-align: left;

    ${ContactCardHeadshot} {
      margin: 0 36px 0 0;
    }
  }
`

const ButtonLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
`

export const pageQuery = graphql`
  {
    allSanityLocation(filter: { status: { ne: "permanently-closed" } }) {
      states: group(field: state) {
        totalCount
        fieldValue
      }
      totalCount
    }
    heroImage: file(relativePath: { eq: "careers/ag-tech-program-hero.jpg" }) {
      ...FullWidthImage
    }
    heroImageMobile: file(relativePath: { eq: "careers/ag-tech-program-hero-mobile.jpg" }) {
      ...FullWidthImage
    }
    gageHeadshot: file(relativePath: { eq: "careers/gage-moore-headshot.png" }) {
      childImageSharp {
        gatsbyImageData(width: 120)
      }
    }
    nextStepImage: file(relativePath: { eq: "careers/take-the-next-step.jpg" }) {
      ...FullWidthImage
    }
    asubImage: file(relativePath: { eq: "careers/arkansas-state-university-logo.jpg" }) {
      ...SharpImage600
    }
    lakeLandImage: file(relativePath: { eq: "careers/lake-land-college-logo.jpg" }) {
      ...SharpImage600
    }
    nwMississippiImage: file(
      relativePath: { eq: "careers/northwest-mississippi-community-college-logo.jpg" }
    ) {
      ...SharpImage600
    }
    vincennesImage: file(relativePath: { eq: "careers/vincennes-university-logo.jpg" }) {
      ...SharpImage600
    }
    owensImage: file(relativePath: { eq: "careers/owens-community-college-logo.jpg" }) {
      ...SharpImage600
    }
  }
`

export default AgTechProgramPage
